body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-layout-header>ul>li:nth-last-of-type(2) {
  display: none;
}

.home_wrapper>.ant-col.home_promo-banner {
  display: none;
}

.home_wrapper>.ant-col {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.schedule_content-container .spanButton:nth-of-type(2) {
  display: none;
}

ul.ant-menu.ant-menu-sub.ant-menu-vertical>li:last-of-type {
  display: none;
}