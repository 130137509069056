.notifications-options-container {
  max-width: 560px;
  margin-top: 32px;

  .email-input {
    margin: 16px 0;
  }
}

.time-pill-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .time-pill-profile {
    display: flex;
    justify-content: center;
    padding: 4px;
    border-radius: 20px;
    white-space: nowrap;
    font-size: 14px;
    background-color: #f0f0f0;
  }
}

.additional-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .additional-fields-item {
    display: flex;
    flex-direction: row;

    .title{
      border: 1px solid;
      padding: 4px;
    }

    .value{
      border: 1px solid;
      padding: 4px;
    }

    .ant-typography{
      margin: 0;
    }
  }
}