.ant-checkbox-wrapper {
    flex-direction: row-reverse;
    margin: 0;
    justify-content: space-between;
    width: 100%;
}

.ant-checkbox-wrapper::after {
    content: none;
}

label.labelCheckbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
}

.custom-checkbox {
    min-width: 16px;
    min-height: 16px;
    margin-right: 8px;
    cursor: pointer;
}