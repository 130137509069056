.ant-drawer-content-wrapper {
    width: 400px;
}
.ant-segmented-item-label{
    padding: 0px;
}
@media (max-width: 400px) {
    .ant-drawer-content-wrapper {
        width: 100%;
    }
}

.ant-badge{
    display: inline-flex;
    margin: 0px;
}

.ant-badge.ant-badge-status{
    display: inline-flex;
    margin: 0px;
}

.ant-tabs-tab{
    height: 46px;
}