.schedule_content-container {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 32px;
  margin: 40px 0;

  .date-range {
    font-size: 18px;
    font-weight: 600;
    color: #8d8d8d;
    margin-top: -16px;
    margin-bottom: 8px;
  }

  .schedule-table {
    margin-top: 32px;
    padding-left: 64px;
    overflow-x: auto;

    .schedule-rows {
      min-width: 800px;
    }

    .ant-tag {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      width: 100%;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      line-height: 1.3;
      padding-top: 4px;
      padding-bottom: 4px;
      cursor: pointer;

      .lesson-title {
        font-weight: 600;
        margin-bottom: 4px;
      }

      .lesson-auditory {
        font-size: 10px;
        margin-bottom: 4px;
      }

      .lesson-group {
        font-size: 10px;
        font-weight: 500;
        opacity: 0.8;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-row:first-child {
      .ant-col {
        padding-top: 0;
      }
    }

    .ant-row:last-child {
      .ant-col {
        border-bottom: none;
      }
    }

    .ant-col {
      border-right: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      padding: 8px;

      &:last-child {
        border-right: none;
      }
    }

    .schedule-row {
      min-height: 64px;
    }

    .schedule-times {
      margin-left: -64px;
      position: absolute;
      display: flex;
      flex-direction: column;
      padding-top: 8px;
      z-index: 1000;

      span:first-child {
        font-weight: bold;
      }

      span:last-child {
        font-size: 12px;
        color: darkgray;
      }
    }
  }

  .schedule_date-title {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-weight: bold;
      text-transform: uppercase;
    }

    span:last-child {
      font-size: 12px;
      color: darkgray;
    }
  }
}

.print-table {
  max-width: 297mm;

  border-collapse: collapse;

  td {
    border: 1px solid black;
  }

  .table-lesson:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid #939393;
    margin-bottom: 8px;
  }

  .lesson-title {
    font-weight: bold;
  }

  .lesson-professor, .lesson-auditory, .lesson-group {
    font-size: 12px;
  }

  tr:first-child {
    td {
      width: auto;
      white-space: nowrap;
    }
  }

  .schedule_date-title {
    div:first-child {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.free-auditory-link {
  color: black;
  display: block;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ececec;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}
