.groups_content-container {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 32px;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
