.time-pill {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  background-color: #f0f0f0;
  margin-right: 16px;
  cursor: pointer;
  margin-bottom: 16px;

  &.selected {
    background-color: #1890ff;
    color: white;
  }
}