.home_content-container {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 32px;
  margin-top: 40px;
}


.home_wrapper {
  justify-content: center;
  align-items: flex-start;


  .home_section-buttons {
    display: flex;
    flex-direction: column;

    button {
      text-align: left;
      font-size: 24px;
      height: auto;
      padding: 16px;
      margin-bottom: 16px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .home_promo-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;

    .home_promo-screenshot {
      width: 100%;
      max-width: 500px;
    }


    .store-badge {
      height: 100%;
      max-height: 40px;
    }

  }
}